<template>
  <v-app>
    <transition name="fade-in-up">
      <div class="row">
        <div class="col-md-12">
          <v-data-table
            :headers="headers"
            :items="contacts"
            :search="search"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            show-expand
            item-key="id"
            sort-by="date_entered"
            sort-desc
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Contacts List</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  ref="search"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                  clearable
                ></v-text-field>

                <!-- Filtros -->
                <v-dialog v-model="dialogFilters" max-width="400px">
                  <template v-slot:activator="{ on }">
                    <!--<v-btn
                      class="ml-4"
                      color="blue-grey"
                      fab
                      small
                      dark
                      v-on="on"
                      @click="$emit = true"
                    >
                      <v-icon>mdi-filter</v-icon>
                    </v-btn>-->
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">Advanced Search</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12">
                            <v-autocomplete
                              :items="filters"
                              v-model="filters.text"
                              label="Filters"
                              :clearable="true"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" sm="12">
                            <v-autocomplete
                              :items="typeList"
                              v-model="typeList.text"
                              label="Type"
                              :clearable="true"
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialogFilters = false"
                        >Close</v-btn
                      >
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="filterItems(filters.text)"
                        >OK</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- Fin Filtros -->

                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="750px">
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" dark class="mb-2" v-on="on"
                      >New Contact</v-btn
                    >
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="6">
                            <v-select
                              v-model="editedItem.type"
                              :items="typeList"
                              label="Type"
                              prepend-icon="mdi-account-details"
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.name"
                              label="Name"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            <v-text-field
                              v-model="editedItem.phone_number"
                              label="Phone Number"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            <v-text-field
                              v-model="editedItem.mail"
                              label="Mail"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            <v-text-field
                              v-model="editedItem.adress"
                              label="Adress"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-autocomplete
                              v-model="editedItem.company"
                              :items="[
                                'Buquebus',
                                'Don Quijote',
                                'Giman S.A.',
                                'Lanchas del Sur S.A.',
                                'Marsa S.R.L.',
                                'Nautica S.R.L.',
                                'Prefectura Naval',
                                'Servi Rio',
                                'ServiPar',
                                'Zurich'
                              ]"
                              label="Company"
                            ></v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.reference"
                              label="Reference"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            <v-text-field
                              v-model="editedItem.interests"
                              label="Interests"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            <v-text-field
                              v-model="editedItem.representation"
                              label="Representation"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4" md="4">
                            <v-text-field
                              v-model="editedItem.cuit"
                              label="Cuit"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-textarea
                              v-model="editedItem.notes"
                              label="Note"
                            ></v-textarea>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-textarea
                              v-model="editedItem.invoicing_details"
                              label="Invoicing Details"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="close">
                        Cancel
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="save">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.type="{ item }">
              <v-chip color="blue" dark v-if="item.type == 'Company'">
                Company
              </v-chip>
              <v-chip color="yellow" v-else-if="item.type == 'Insurance'">
                Insurance
              </v-chip>
              <v-chip color="green" dark v-else-if="item.type == 'Lawfirm'">
                Lawfirm
              </v-chip>
              <v-chip color="green" dark v-else-if="item.type == 'Lawyer'">
                Lawyer
              </v-chip>
              <v-chip color="gray" dark v-else-if="item.type == 'Surveyor'">
                Surveyor
              </v-chip>
              <v-chip color="gray" v-else-if="item.type == 'Agent'">
                Agent
              </v-chip>
              <v-chip color="red" dark v-else-if="item.type == 'Client'">
                Client
              </v-chip>
              <v-chip color="orange" dark v-else>
                Person
              </v-chip>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
                v-b-tooltip.hover
                title="Edit"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                small
                @click="deleteItem(item)"
                v-b-tooltip.hover
                title="Delete"
              >
                mdi-delete
              </v-icon>
            </template>
            <template v-slot:expanded-item="{ item }">
              <td :colspan="headers.length + 1">
                <div class="row justify-center">
                  <div class="col-md-11">
                    <div class="row">
                      <div class="col-md-6">
                        <p>
                          <b>Adress:</b><br />
                          {{ item.adress }}
                        </p>
                      </div>
                      <div class="col-md-3">
                        <p>
                          <b>Reference:</b><br />
                          {{ item.reference }}
                        </p>
                      </div>
                      <div class="col-md-3">
                        <p>
                          <b>Interests:</b><br />
                          {{ item.interests }}
                        </p>
                      </div>
                      <div class="col-md-3">
                        <p>
                          <b>Representation:</b><br />
                          {{ item.representation }}
                        </p>
                      </div>
                      <div class="col-md-3">
                        <p>
                          <b>CUIT:</b><br />
                          {{ item.cuit }}
                        </p>
                      </div>
                      <div class="col-md-3">
                        <p>
                          <b>Notes:</b><br />
                          {{ item.notes }}
                        </p>
                      </div>
                      <div class="col-md-3">
                        <p>
                          <b>Invoicing Details:</b><br />
                          {{ item.invoicing_details }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize">Reset</v-btn>
            </template>
          </v-data-table>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import genericService from "./../../../services/generic.service";
import Swal from "sweetalert2";

export default {
  data: () => ({
    uri: "contacts",
    dialog: false,
    search: "",
    expanded: [],
    singleExpand: false,
    headers: [
      { text: "Type", value: "type", filterable: true },
      { text: "Name", value: "name", align: "start", filterable: true },
      { text: "Company", value: "company", filterable: true },
      { text: "Phone", value: "phone_number", filterable: true },
      { text: "Mail", value: "mail", filterable: true },
      { text: "Actions", value: "actions" }
    ],
    headers_extra: [
      { text: "Adress", value: "adress" },
      { text: "Reference", value: "reference" },
      { text: "Interests", value: "interests" },
      { text: "Representation", value: "representation" },
      { text: "CUIT", value: "cuit" },
      { text: "Notes", value: "notes" },
      { text: "Invoicing Details", value: "invoicing_details" }
    ],
    contacts: [],
    editedIndex: -1,
    editedItem: {
      type: "",
      company: "",
      name: "",
      adress: "",
      phone_number: 0,
      mail: "",
      cuit: "",
      notes: "",
      invoicing_details: "",
      interests: "",
      representation: ""
    },
    defaultItem: {
      type: "",
      company: "",
      name: "",
      adress: "",
      phone_number: 0,
      mail: "",
      cuit: "",
      notes: "",
      invoicing_details: "",
      interests: "",
      representation: ""
    },
    switch1: true,

    /* Filtros & Loading */
    loading: true,
    filters: [
      { text: "All", value: 99 },
      { text: "Name", value: 1 },
      { text: "Company", value: 2 },
      { text: "Phone", value: 3 },
      { text: "Mail", value: 4 }
    ],
    typeList: [
      { text: "Company", value: "Company" },
      { text: "Lawfirm", value: "Lawfirm" },
      { text: "Lawyer", value: "Lawyer" },
      { text: "Client", value: "Client" },
      { text: "Person", value: "Person" },
      { text: "Insurance", value: "Insurance" },
      { text: "Surveyor", value: "Surveyor" },
      { text: "Agent", value: "Agent" }
    ],
    dialogFilters: false,
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Contact" : "Edit Contact";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
  },

  methods: {
    initialize() {
      var _self = this;
      genericService.getRecordList('/'+this.uri, function(records){
          _self.contacts = records
      });
    },

    editItem(item) {
      this.editedIndex = this.contacts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        const index = this.contacts.indexOf(item);
        if (result.value) {
          genericService.deleteItem(this.uri, item.id);
          this.contacts.splice(index, 1);
          Swal.fire({
            title: 'Deleted!',
            text: "Your record has been deleted.",
            icon: 'success'
          })
        }
      })
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.contacts[this.editedIndex], this.editedItem);
        genericService.updateRecord("/"+this.uri+"/" + this.editedItem.id, this.editedItem, function(){
          Swal.fire({
            title: "",
            text: "Record has been successfully saved!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          });
        });
      } else {
        this.contacts.push(this.editedItem);
        genericService.createRecord("/"+this.uri, this.editedItem, function(){

           Swal.fire({
            title: '',
            text: "Record has been successfully saved!",
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.value) {
              location.reload();
            }
          })

        });
      }
      this.close();
    },

    /* Filtros & Loading */
    currentItems(item) {
      this.loading = true;
      setTimeout(() => {
        this.totalTime = item.reduce((a, b) => a + (b["time"] || 0), 0);
        /* Filtros */
        this.itemsFiltered = item.length;
        this.loading = false;
      }, 300);
    },

    filterItems(itemFiltered) {
      if (itemFiltered) {
        if (itemFiltered == 99) {
          this.headers.forEach(header => (header.filterable = true));
        } else {
          this.headers.forEach(header => (header.filterable = false));
          this.headers[itemFiltered].filterable = true;
        }
      } else {
        this.headers.forEach(header => (header.filterable = true));
      }
      this.dialogFilters = false;
      return this.headers;
    }

  },
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Contacts" }]);
    setTimeout(() => {
      this.$refs.search.focus();
    }, 500);
    
    this.initialize();
  }
};
</script>
